import {
    getCategory,
    getCategoryGoods,
    getHomeGoods
} from '@/utils/home.js';
export default {
    data() {
        return {
            categaryList: [{
                    category_name: '全部',
                    category_id: ''
                },
                {
                    category_name: '人气推荐',
                    category_id: ''
                },
                {
                    category_name: '本周特惠',
                    category_id: ''
                },
            ],
            sIndex: 0,
            images: [
                'http://sfwx.cn-sh2.ufileos.com/banner/header.jpg'
            ],
            page: 1,
            oldPage: 0,
            brandId: '',
            tagName: '',
            categoryId: '',
            goodsList: [],
            finished: false,
            loading: false,
            selectTagName: ''
        }
    },
    watch: {
        $route(to, from) {
            if (to.name === 'goodsDetail') {
                from.meta.keepAlive = true;
                from.meta.isBack = false;
            } else if (to.name === 'home') {
                console.log('leave', to, from)
                from.meta.isBack = true;
                from.meta.keepAlive = false;
            }
        }
    },
    created() {
        this.categoryId = this.$route.query.category;
        this.userType = JSON.parse(sessionStorage.getItem('userInfo')).user_type;
        this.getCategoryFunc()
    },
    methods: {
        // 获取左边分类
        getCategoryFunc() {
            let data = {
                goodBigtype: 2,
                level: 2
            }
            getCategory(data).then(res => {
                console.log('分类', res.data)
                let arr = JSON.parse(JSON.stringify(res.data.category_list));
                this.categaryList = this.categaryList.concat(arr);
                if (!this.categoryId) {
                    this.tagName = '';
                    this.selectTagName = '全部';
                } else {
                    this.categaryList.forEach((item, index) => {
                        if (item.category_id == this.categoryId) {
                            this.sIndex = index;
                            this.selectTagName = item.category_name;
                        }
                    })
                }

                this.getHomeGoodsFunc()
            })
        },
        // 获取商品
        getHomeGoodsFunc() {
            if (this.oldPage == this.page) return false;
            this.oldPage = this.page;
            let data = {
                memberId: this.$cookies.get('memberId'),
                goodName: '',
                sortMethod: 1,
                pageNo: this.page,
                pageSize: 10,
                channel: '',
                categoryFirstId: this.categoryId,
                tag: this.tagName
            }
            getHomeGoods(data).then(res => {
                console.log('获取商品', res)
                if (res) {
                    let arr = res.result ? res.result.goodList : [];
                    arr.forEach(item => {
                            this.goodsList.push(item)
                        })
                        // 加载状态结束
                    this.loading = false;

                    // 数据全部加载完成
                    if (arr.length < 10) {
                        this.finished = true;
                    } else {
                        this.page++;
                    }
                } else {
                    this.finished = true;
                }
            })
        },
        // 通过 品牌获取商品
        getCategoryGoodsFunc() {
            let data = {
                brandId: this.brandId,
                pageNo: this.page,
                pageSize: 10
            }
            getCategoryGoods(data).then(res => {
                console.log('商品', res)
            })
        },
        // 选择分类
        selectCategary(idx) {
            this.sIndex = idx;
            if (idx == 0) {
                this.tagName = '';
            } else {
                this.tagName = !this.categaryList[idx].category_id ? this.categaryList[idx].category_name : '';
            }
            this.selectTagName = this.categaryList[idx].category_name
            this.categoryId = this.categaryList[idx].category_id;
            this.page = 1;
            this.oldPage = 0;
            this.loading = false;
            this.finished = false;
            this.goodsList = [];
            this.getHomeGoodsFunc();
        },
        // 跳转到商品详情
        gotoPageFunc(id) {
            this.$router.push({
                path: '/goodsDetail',
                query: {
                    goodsId: id
                }
            })
        },
        // 跳转到首页年货专区
        goHomeFunc() {
            this.$router.push({
                path: '/',
                query: {
                    tagIdx: 1
                }
            })
        }
    },
}