<template>
  <div class="flex flex-column categary">
    <div class="flex flex-between categary-main">
      <ul class="categary-main-l">
        <li class="categary-main-l-list" v-for="(item,index) in categaryList" :key="item.id" @click="selectCategary(index)">
          <div class="flex flex-center align-center categary-main-l-list-div" :class="sIndex == index ? 'categary-main-l-lists' : (sIndex == 0 ? ((index - sIndex  == 1) ? 'categary-main-l-list2':'') : (index - sIndex  == 1) ? 'categary-main-l-list2' : (sIndex - index  == 1 ? 'categary-main-l-list1' : ''))">
            {{item.category_name}}<i v-if="sIndex == index" class="categary-main-l-lists-i"></i>
          </div>
        </li>
      </ul>
      <ul class="categary-main-r">
        <li class="categary-main-r-li">
          <div class="categary-main-r-li-swiper">
            <van-swipe class="categary-main-r-li-swiper-main">
              <van-swipe-item v-for="image in images" :key="image" @click="goHomeFunc">
                <img :src="image" />
              </van-swipe-item>
            </van-swipe>
          </div>
          <div class="flex flex-left align-center categary-main-r-li-tag">
            <i v-if="selectTagName != ''"></i>{{selectTagName}}</div>
          <van-list
            v-if="goodsList.length > 0"
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            :immediate-check="false"
            @load="getHomeGoodsFunc"
          >
            <ul class="categary-main-r-li-list">
              <li class="flex flex-left categary-result-list" v-for="item in goodsList" :key="item.id" @click="gotoPageFunc(item.goodId)">
                <div class="categary-result-list-l" :style="{background: 'url('+ item.goodPic +')no-repeat center center', backgroundSize: 'cover'}">
                  <!-- <img :src="item.goodPic" alt=""> -->
                  <div class="flex flex-center align-center categary-result-list-l-tag" v-if="item.tag">{{item.tag}}</div>
                </div>
                <div class="categary-result-list-r">
                  <div class="categary-result-list-r-name">
                    {{item.goodName}}
                  </div>
                  <div class="categary-result-list-r-brand">品牌：{{item.brandName}}</div>
                  <div class="flex flex-left flex-wrap categary-result-list-r-yh">
                    <span class="" v-if="item.coupon">{{item.coupon.name}}</span>
                    <span class="" v-if="item.score">得{{item.score}}龙珠</span>
                  </div>
                  <!-- flex flex-between align-center  -->
                  <div class="categary-result-list-r-price">
                    <div class="categary-result-list-r-price-l">
                      ¥<span class="categary-result-list-r-price-l-num">{{item.price}}</span>起
                      <span class="categary-result-list-r-price-l-toast">灵龙价</span>
                    </div>
                    <!-- <div class="categary-result-list-r-price-sale">销量{{item.saleNum}}</div> -->
                    <div class="categary-result-list-r-price-sale" style="text-decoration: line-through;color:#999;">原价¥{{parseFloat(item.goodsOriginalPrice/100).toFixed(2)}}</div>
                  </div>
                  <div class="flex flex-left tj-profit" v-if="item.returnAmount > 0">
                    <span>推荐粉丝返¥{{parseFloat(item.returnAmount/100).toFixed(2)}}</span>
                  </div>
                </div>
              </li>
            </ul>
          </van-list>
          <van-empty v-if="goodsList.length == 0" description="暂无数据" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
  @import "./index.scss";
</style>